const initialValues = {
  session_id: '',
  affid: '',
  user_agent: '',
  universal_leadid: '',
  ip_address: '',
  sub_id1: '',
  sub_id2: '',
  sub_id3: '',
  datetime_collected: '',
  client_name: '',
  tcpa_disclosure: '1',
  tcpa_consent_language: ``,
  landing_url: `${process.env.GATSBY_WEB_SITE_URL}`,
  privacy_policy_url: `${process.env.GATSBY_POLICY_URL}`,
  testing: ''
}

export default (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_AFF_PARAMS':
      return {
        ...state,
        sub_id1: state.sub_id1 || action.payload.sub_id1,
        sub_id2: state.sub_id2 || action.payload.sub_id2,
        sub_id3: state.sub_id3 || action.payload.sub_id3,
        client_name: state.client_name || action.payload.client_name,
      }
    case 'SET_IP_ADDRESS':
      return {
        ...state,
        ip_address: action.payload.ip_address
      }
    case 'SET_UNIVERSAL_LEAD_ID':
      return {
        ...state,
        universal_leadid: action.payload.universal_leadid
      }
    case 'SET_USER_AGENT':
      return {
        ...state,
        user_agent: action.payload.user_agent
      }
    default:
      return state;
    }
};