const initialValues = {
  sourceId: "LOCALHOST",
  CreatedByAgent: true,
  agentId: "3590333",
  effectiveDate: "",
  tabCode: 'STM',
  zip: "",
  sex: "",
  dateOfBirth: "",
  tobaccoUse: "",
  spouse: [],
  children: []
}

export default (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      const {name, value} = action.payload
      return { 
        ...state, [name]: value 
      }
    case 'SET_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state
      }
    case 'CLEAR_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state
      }
    default:
      return state;
    }
};