import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import rootReducer from '.';

let createStore;
if(process.env.GATSBY_REDUX === 'develop') {
  createStore = () => reduxCreateStore(
    rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && 
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  createStore = () => reduxCreateStore(rootReducer);
}
export default ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
);